import {
    Box,
    Button,
    Dialog,
    DialogContent,
    FormControl,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
} from "@mui/material";

import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import TooltipButton from "./TooltipButton";

/**
 * @param {object} props
 * @param {boolean} props.open - value whether dialog window is open
 * @param {Function} props.handleClose - function to call when closing dialog
 * @param {string | {}} props.mode - either 'create' or user data to be edited as object
 * @param {Function} props.handleCreate - function to create user
 * @param {Function} props.handleEdit - function to edit user
 * @returns  {JSX.Element} custom dialog for creating new user
 */
const UserDialog = ({ open, handleClose, mode, handleCreate, handleEdit }) => {
    // i18n
    const { t } = useTranslation();

    // boolean value to show or hide password
    const [passwordVisible, togglePasswordVisible] = useState(false);

    // form data
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        userRole: "",
    });

    useEffect(() => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            firstName: mode?.FirstName ? mode.FirstName : "",
            lastName: mode?.LastName ? mode.LastName : "",
            email: mode?.Email ? mode.Email : "",
            password: "",
            userRole: mode?.Scope ? mode.Scope : "",
        }));
    }, [open]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [`${name}`]: value,
        }));
    };

    return (
        <Dialog open={open}>
            <DialogContent>
                <Stack direction="column">
                    {/* dialog header */}
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={2}
                    >
                        {/* dialog title */}
                        <Typography
                            variant="h3"
                            className="dialogHeadline"
                            sx={{ marginLeft: "10px !important" }}
                        >
                            {mode === "create"
                                ? t("create_user")
                                : t("edit_user")}
                        </Typography>

                        {/* close dialog button */}
                        <TooltipButton
                            tooltip={`${t("close")}`}
                            handleClick={handleClose}
                            icon="close"
                            iconStyle={{ color: "white !important" }}
                            buttonClass="cardIcon"
                            iconClass="cardIcon"
                            ariaLabel="close current dialogue"
                        />
                    </Stack>
                </Stack>

                <Box
                    component="form"
                    noValidate
                    sx={{
                        mt: 3,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "end",
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                fullWidth
                                autoComplete="off"
                                name="firstName"
                                label={t("first_name")}
                                value={formData.firstName}
                                onChange={(e) => {
                                    handleChange(e);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                fullWidth
                                autoComplete="off"
                                name="lastName"
                                label={t("last_name")}
                                value={formData.lastName}
                                onChange={(e) => {
                                    handleChange(e);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                fullWidth
                                autoComplete="off"
                                name="email"
                                label={t("email")}
                                value={formData.email}
                                onChange={(e) => {
                                    handleChange(e);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required={mode === "create"}
                                fullWidth
                                autoComplete="new-password"
                                type={passwordVisible ? "text" : "password"}
                                name="password"
                                label={t("password")}
                                value={formData.password}
                                onChange={(e) => {
                                    handleChange(e);
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <TooltipButton
                                                ariaLabel="toggle password visibility"
                                                tooltip={
                                                    passwordVisible
                                                        ? "hide password"
                                                        : "show password"
                                                }
                                                icon={
                                                    passwordVisible
                                                        ? "visibilityOff"
                                                        : "visibility"
                                                }
                                                handleClick={() =>
                                                    togglePasswordVisible(
                                                        !passwordVisible
                                                    )
                                                }
                                                iconStyle={{
                                                    color: "white !important",
                                                }}
                                                buttonStyle={{
                                                    marginRight: "-5px",
                                                }}
                                            />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <InputLabel id="user-role-label">
                                    {t("role")}
                                </InputLabel>
                                <Select
                                    labelId="user-role-label"
                                    id="user-role"
                                    name="userRole"
                                    value={formData.userRole}
                                    onChange={(e) => {
                                        handleChange(e);
                                    }}
                                    label={t("role")}
                                >
                                    <MenuItem value="tecAdmin">
                                        tecAdmin
                                    </MenuItem>
                                    <MenuItem value="busAdmin">
                                        busAdmin
                                    </MenuItem>
                                    <MenuItem value="User">User</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{
                            mt: 3,
                            width: "max-content",
                            borderRadius: "10px",
                        }}
                        onClick={() => {
                            mode === "create"
                                ? handleCreate(formData)
                                : handleEdit(formData);
                        }}
                    >
                        {mode === "create" ? t("create_user") : t("edit_user")}
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default UserDialog;
