/**
 * @param {object} props
 * @param {JSX.Element[]} props.children - routes to protect
 * @returns {JSX.Element} custom route to only allow access when signed in
 */

const ProtectedRoute = ({ children }) => {
    return children;
};

export default ProtectedRoute;
