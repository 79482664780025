import { Chip } from "@mui/material";

import { useTranslation } from "react-i18next";
import CustomTooltip from "./CustomTooltip";

/**
 * @param {object} props
 * @param {string} statusCode - document status code as string
 * @returns {JSX.Element} custom chip for homepage
 */

const HomepageChip = ({ statusCode }) => {
    // i18n
    const { t } = useTranslation();

    return (
        <CustomTooltip title={t(`status_${statusCode}`.toLowerCase())}>
            <Chip
                className={
                    "homepage " +
                    (statusCode === "100"
                        ? "initial"
                        : statusCode === "101"
                        ? "accepted"
                        : statusCode === "102"
                        ? "ready"
                        : statusCode === "200"
                        ? "processed"
                        : statusCode === "201"
                        ? "rejected"
                        : statusCode === "400"
                        ? "rejected"
                        : statusCode === "500"
                        ? "error"
                        : statusCode === "blocked"
                        ? "error"
                        : "")
                }
                label={t(`status_${statusCode}`.toLowerCase())}
            />
        </CustomTooltip>
    );
};

export default HomepageChip;
