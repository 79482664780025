import AddIcon from "@mui/icons-material/Add";
import { Button, Grid, Skeleton, Stack, Typography } from "@mui/material";

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useAuth } from "../AuthProvider";

import ConfirmDialog from "../components/ConfirmDialog";
import ModuleCard from "../components/ModuleCard";
import ModuleCreate from "../components/ModuleCreate";
import ModuleDetails from "../components/ModuleDetails";

export default function Config() {
    // i18n
    const { t } = useTranslation();
    // authorization
    const { request } = useAuth();

    // endpoints in use
    const endpoint = `${process.env.REACT_APP_BACKEND_URL}/module`;

    // all invoice details from database
    const [loadingData, setLoadingData] = useState(false);
    const [data, setData] = useState(null);

    // value whether detail dialogue window for certain module is open
    const [openDetail, setOpenDetail] = useState(null);
    // boolean value whether create dialogue window is open
    const [openCreate, setOpenCreate] = useState(false);
    // value whether delete dialogue window for certain module is open
    const [openDelete, setOpenDelete] = useState(null);
    // value whether configuration for certain module is editable
    const [editable, setEditable] = useState(null);

    const fetchData = async (init = false) => {
        if (init) {
            setLoadingData(true);
        }
        const response = await request("get", endpoint, null, null, []);

        if (response?.status === 200 || response?.status === 204) {
            setData(response.data);
            setTimeout(() => {
                setLoadingData(false);
            }, 1000);
        }
    };

    useEffect(() => {
        fetchData(true);
    }, []);

    // function to construct oauth url with correct client and tenant id
    const constructOauthUrl = (
        oaut_url,
        client_id,
        tenant_id,
        redirect_url
    ) => {
        return oaut_url
            .replace("{client_id}", client_id)
            .replace("{tenant_id}", tenant_id)
            .replace("{redirect_url}", encodeURIComponent(redirect_url));
    };

    const handleCreate = async (moduleName, config) => {
        // create request body with new data
        const body = {
            module_name: `${moduleName}`,
            configuration: config,
        };

        const response = await request(
            "post",
            endpoint,
            body,
            "application/json",
            [
                {
                    statusCode: 201,
                    type: "success",
                    message: t("create_success", {
                        prop: moduleName,
                    }),
                },
                {
                    statusCode: 400,
                    type: "error",
                    message: t("create_failure", {
                        prop: moduleName ? moduleName : "Module",
                    }),
                },
                {
                    statusCode: 409,
                    type: "warning",
                    message: t("create_conflict", {
                        prop: t("configuration", { count: 1 }),
                        name: moduleName,
                    }),
                },
                {
                    statusCode: 422,
                    type: "warning",
                    message: [
                        "Module name",
                        t("module_invalid_name"),
                        t("module_invalid_config"),
                    ],
                },
            ]
        );

        if (response?.status === 201) {
            setOpenCreate(false);
            fetchData();
        }
    };

    const handleSave = async (moduleName, config) => {
        // create request body with new data
        const body = {
            module_name: `${moduleName}`,
            configuration: config,
        };

        const response = await request(
            "put",
            endpoint,
            body,
            "application/json",
            [
                {
                    statusCode: 200,
                    type: "success",
                    message: t("edit_success", {
                        prop: moduleName,
                    }),
                },
                {
                    statusCode: 400,
                    type: "error",
                    message: t("edit_failure", {
                        prop: moduleName,
                    }),
                },
                {
                    statusCode: 422,
                    type: "warning",
                    message: [
                        "Module name",
                        t("module_invalid_name"),
                        t("module_invalid_config"),
                    ],
                },
            ]
        );

        if (response?.status === 200) {
            turnOffEdit();
            fetchData();
        }
    };

    const handleDelete = async (moduleName, onDetail) => {
        const response = await request(
            "delete",
            endpoint + "/" + encodeURIComponent(moduleName),
            null,
            null,
            [
                {
                    statusCode: 200,
                    type: "success",
                    message: t("delete_success", {
                        prop: moduleName,
                    }),
                },
                {
                    statusCode: 400,
                    type: "error",
                    message: t("delete_failure", {
                        prop: moduleName,
                    }),
                },
            ]
        );

        if (response?.status === 200) {
            if (onDetail) {
                handleCloseDetail(true);
            }
            handleCloseDelete(true);
        }
    };

    // function to set name of module whose outlook server has been started
    const handleServerStart = (moduleName) => {
        if (moduleName) {
            localStorage.setItem("moduleToUpdate", moduleName);
        } else {
            fetchData();
        }
    };

    const handleOpenDetail = (item) => {
        setOpenDetail(item);
    };

    const handleCloseDetail = (afterDeletion) => {
        setOpenDetail(null);
        setEditable(null);
        if (afterDeletion) {
            fetchData();
        }
    };

    const handleOpenDelete = (item) => {
        setOpenDelete(item);
    };

    const handleCloseDelete = (afterDeletion) => {
        setOpenDelete(null);
        if (afterDeletion) {
            fetchData();
        }
    };

    const turnOnEdit = (item) => {
        setEditable(item);
    };

    const turnOffEdit = () => {
        setEditable(null);
    };

    return (
        <>
            {/* main container */}
            <Stack
                direction="column"
                spacing={1}
                sx={{
                    alignSelf: "center",
                    width: { md: "100%", lg: "90%", xl: "70%" },
                }}
            >
                {/* heading */}
                <Typography variant="h1" className="pageHeadline">
                    {t("configuration_title")}
                </Typography>
                {/* card container */}
                <Stack direction="row" spacing={1}>
                    <Grid
                        container
                        spacing={{ xs: 3, md: 4, lg: 3 }}
                        sx={{
                            mx: {
                                md: "-30px !important",
                                lg: "-26px !important",
                            },
                        }}
                    >
                        {/* create config button */}
                        <Grid item sm={6} md={4} lg={3} xl={2.4}>
                            <Button
                                variant="outlined"
                                color="secondary"
                                onClick={() => {
                                    setOpenCreate(true);
                                }}
                                className="createModule"
                                sx={{
                                    height:
                                        loadingData || !data
                                            ? {
                                                  md: "400px",
                                                  lg: "440px",
                                                  xl: "333px",
                                                  xxl: "400px",
                                              }
                                            : "100%",
                                    width:
                                        loadingData || !data
                                            ? {
                                                  md: "360px",
                                                  lg: "390px",
                                                  xl: "250px",
                                                  xxl: "325px",
                                              }
                                            : "100%",
                                    borderRadius: "25px",
                                }}
                            >
                                <Stack className="createModule" spacing={2}>
                                    <Typography
                                        variant="body1"
                                        className="createModule"
                                    >
                                        {t("module_create")}
                                    </Typography>
                                    <AddIcon className="createModule" />
                                </Stack>
                            </Button>
                        </Grid>

                        {loadingData && (
                            <>
                                <Grid item sm={6} md={4} lg={3} xl={2.4}>
                                    <Stack
                                        direction="column"
                                        spacing={2}
                                        sx={{ padding: "15px" }}
                                    >
                                        <Skeleton
                                            variant="rounded"
                                            sx={{
                                                borderRadius: "25px",
                                                width: "100%",
                                                height: {
                                                    md: "200px",
                                                    lg: "215px",
                                                    xl: "130px",
                                                    xxl: "175px",
                                                },
                                            }}
                                        />
                                        <Skeleton
                                            variant="rounded"
                                            sx={{
                                                borderRadius: "25px",
                                                height: {
                                                    md: "95px",
                                                    lg: "110px",
                                                    xl: "90px",
                                                    xxl: "95px",
                                                },
                                            }}
                                        />
                                        <Stack
                                            direction="row"
                                            justifyContent="space-between"
                                        >
                                            <Skeleton
                                                variant="circular"
                                                sx={{
                                                    width: {
                                                        md: "40px",
                                                        lg: "50px",
                                                        xl: "40px",
                                                        xxl: "45px",
                                                    },
                                                    height: {
                                                        md: "40px",
                                                        lg: "50px",
                                                        xl: "40px",
                                                        xxl: "45px",
                                                    },
                                                }}
                                            />
                                            <Skeleton
                                                variant="circular"
                                                sx={{
                                                    width: {
                                                        md: "40px",
                                                        lg: "50px",
                                                        xl: "40px",
                                                        xxl: "45px",
                                                    },
                                                    height: {
                                                        md: "40px",
                                                        lg: "50px",
                                                        xl: "40px",
                                                        xxl: "45px",
                                                    },
                                                }}
                                            />
                                        </Stack>
                                    </Stack>
                                </Grid>
                                <Grid item sm={6} md={4} lg={3} xl={2.4}>
                                    <Stack
                                        direction="column"
                                        spacing={2}
                                        sx={{ padding: "15px" }}
                                    >
                                        <Skeleton
                                            variant="rounded"
                                            sx={{
                                                borderRadius: "25px",
                                                width: "100%",
                                                height: {
                                                    md: "200px",
                                                    lg: "215px",
                                                    xl: "130px",
                                                    xxl: "175px",
                                                },
                                            }}
                                        />
                                        <Skeleton
                                            variant="rounded"
                                            sx={{
                                                borderRadius: "25px",
                                                height: {
                                                    md: "95px",
                                                    lg: "110px",
                                                    xl: "90px",
                                                    xxl: "95px",
                                                },
                                            }}
                                        />
                                        <Stack
                                            direction="row"
                                            justifyContent="space-between"
                                        >
                                            <Skeleton
                                                variant="circular"
                                                sx={{
                                                    width: {
                                                        md: "40px",
                                                        lg: "50px",
                                                        xl: "40px",
                                                        xxl: "45px",
                                                    },
                                                    height: {
                                                        md: "40px",
                                                        lg: "50px",
                                                        xl: "40px",
                                                        xxl: "45px",
                                                    },
                                                }}
                                            />
                                            <Skeleton
                                                variant="circular"
                                                sx={{
                                                    width: {
                                                        md: "40px",
                                                        lg: "50px",
                                                        xl: "40px",
                                                        xxl: "45px",
                                                    },
                                                    height: {
                                                        md: "40px",
                                                        lg: "50px",
                                                        xl: "40px",
                                                        xxl: "45px",
                                                    },
                                                }}
                                            />
                                        </Stack>
                                    </Stack>
                                </Grid>
                                <Grid item sm={6} md={4} lg={3} xl={2.4}>
                                    <Stack
                                        direction="column"
                                        spacing={2}
                                        sx={{ padding: "15px" }}
                                    >
                                        <Skeleton
                                            variant="rounded"
                                            sx={{
                                                borderRadius: "25px",
                                                width: "100%",
                                                height: {
                                                    md: "200px",
                                                    lg: "215px",
                                                    xl: "130px",
                                                    xxl: "175px",
                                                },
                                            }}
                                        />
                                        <Skeleton
                                            variant="rounded"
                                            sx={{
                                                borderRadius: "25px",
                                                height: {
                                                    md: "95px",
                                                    lg: "110px",
                                                    xl: "90px",
                                                    xxl: "95px",
                                                },
                                            }}
                                        />
                                        <Stack
                                            direction="row"
                                            justifyContent="space-between"
                                        >
                                            <Skeleton
                                                variant="circular"
                                                sx={{
                                                    width: {
                                                        md: "40px",
                                                        lg: "50px",
                                                        xl: "40px",
                                                        xxl: "45px",
                                                    },
                                                    height: {
                                                        md: "40px",
                                                        lg: "50px",
                                                        xl: "40px",
                                                        xxl: "45px",
                                                    },
                                                }}
                                            />
                                            <Skeleton
                                                variant="circular"
                                                sx={{
                                                    width: {
                                                        md: "40px",
                                                        lg: "50px",
                                                        xl: "40px",
                                                        xxl: "45px",
                                                    },
                                                    height: {
                                                        md: "40px",
                                                        lg: "50px",
                                                        xl: "40px",
                                                        xxl: "45px",
                                                    },
                                                }}
                                            />
                                        </Stack>
                                    </Stack>
                                </Grid>
                            </>
                        )}

                        {!loadingData && data && data.length > 0 ? (
                            // each card
                            data.map((d, index) => (
                                <Grid
                                    item
                                    sm={6}
                                    md={4}
                                    lg={3}
                                    xl={2.4}
                                    key={`${index}_card`}
                                >
                                    <ModuleCard
                                        moduleName={d["Module"]}
                                        creationTime={d["CreateTimestamp"]}
                                        modificationTime={d["ChangeTimestamp"]}
                                        config={JSON.parse(d?.Configuration)}
                                        oauthUrl={constructOauthUrl(
                                            process.env.REACT_APP_OAUT_URL,
                                            JSON.parse(d?.Configuration)
                                                .GRAPH_API?.client_id,
                                            JSON.parse(d?.Configuration)
                                                .GRAPH_API?.tenant_id,
                                            JSON.parse(d?.Configuration)
                                                .GRAPH_API?.redirect_uri
                                        )}
                                        handleOpen={() =>
                                            handleOpenDetail(d.Module)
                                        }
                                        handleOpenEdit={() => {
                                            handleOpenDetail(d.Module);
                                            turnOnEdit(d.Module);
                                        }}
                                        handleServerStart={() => {
                                            handleServerStart(d.Module);
                                        }}
                                        handleDelete={() => {
                                            handleOpenDelete(d.Module);
                                        }}
                                        ariaLabel={`${d.module} module card with index ${index}`}
                                    />
                                </Grid>
                            ))
                        ) : (
                            <></>
                        )}

                        {data &&
                            data.map((d, index) => (
                                // detail view
                                <ModuleDetails
                                    compKey={`${index}_detail`}
                                    data={d}
                                    config={JSON.parse(d.Configuration)}
                                    isOpen={openDetail === d.Module}
                                    isEditable={editable === d.Module}
                                    handleClose={() => handleCloseDetail()}
                                    handleEdit={() => turnOnEdit(d.Module)}
                                    handleDelete={handleDelete}
                                    handleSave={handleSave}
                                    handleCancel={() => turnOffEdit()}
                                />
                            ))}

                        {data &&
                            data.map((d, index) => (
                                // delete dialogue
                                <ConfirmDialog
                                    isOpen={openDelete === d.Module}
                                    headline={t("module_delete")}
                                    question={t("module_delete_question", {
                                        module_name: d.Module,
                                    })}
                                    handleClose={() => handleCloseDelete()}
                                    handleConfirm={() => {
                                        handleDelete(d.Module);
                                    }}
                                />
                            ))}

                        <ModuleCreate
                            open={openCreate}
                            handleClose={() => {
                                setOpenCreate(false);
                            }}
                            handleCreate={handleCreate}
                        />
                    </Grid>
                </Stack>
            </Stack>
        </>
    );
}
