import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import CloseIcon from "@mui/icons-material/CloseOutlined";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import {
    DataGrid,
    GridActionsCellItem,
    GridRowEditStopReasons,
    GridRowModes,
    GridToolbarContainer,
} from "@mui/x-data-grid";

import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { useAuth } from "../AuthProvider";
import { ButtonGroup, Skeleton, Stack } from "@mui/material";

import ConfirmDialog from "../components/ConfirmDialog";

function EditToolbar(props) {
    // i18n
    const { t } = useTranslation();

    // Destructure rows from props
    const { rows, setRows, setRowModesModel } = props;

    const handleClick = () => {
        const maxId =
            rows.length > 0 ? Math.max(...rows.map((row) => row.Id)) : 0;

        const Id = maxId + 1;
        const id = Id;

        setRows((oldRows) => [
            ...oldRows,
            {
                SAPValue: "",
                XAVVField: "",
                XAVVTable: "",
                XAVVValue: "",
                id,
                Id,
                age: "",
                isNew: true,
            },
        ]);
        setRowModesModel((oldModel) => ({
            ...oldModel,
            [Id]: { mode: GridRowModes.Edit, fieldToFocus: "name" },
        }));
    };

    return (
        <GridToolbarContainer sx={{ padding: "5px 5px 7px" }}>
            <Button
                variant="contained"
                color="secondary"
                startIcon={<AddIcon />}
                onClick={handleClick}
            >
                {t("add_record")}
            </Button>
        </GridToolbarContainer>
    );
}

export default function SapConfiguration() {
    //i18n
    const { t } = useTranslation();

    const { request } = useAuth();

    //endpoints in use
    const endpoint = `${process.env.REACT_APP_BACKEND_URL}/mapping`;
    const endpoint_delete = `${process.env.REACT_APP_BACKEND_URL}/delete_mapping`;

    // data
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = React.useState([]);

    // editing or viewing mode
    const [rowModesModel, setRowModesModel] = React.useState({});

    // confirm dialogue
    const [openDialog, setOpenDialog] = useState(false);

    const [hasChanges, setHasChanges] = useState(false);
    const [deleteIds, setDeleteIds] = useState([]);

    const fetchData = async (init = false) => {
        if (init) {
            setLoading(true);
        }

        const response = await request("get", endpoint, null, null, [
            {
                statusCode: 400,
                type: "error",
                message: t("load_failure", { prop: "Mappings" }),
            },
        ]);

        if (response?.status === 200) {
            const dataWithIds = response.data.map((row) => ({
                ...row,
                id: row["Id"],
            }));
            setRows(dataWithIds);
            setLoading(false);
        } else if (response?.status === 200 || response?.status === 204) {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData(true);
    }, []);

    // methods for when user clicks on edit button per row
    const handleEditClick = (id) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.Edit },
        });
    };

    const handleRowModesModelChange = (newRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    // methods for when user clicks on save button per row
    const handleSaveClick = (id) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View },
        });
        setHasChanges(true);
    };

    const processRowUpdate = (newRow) => {
        const updatedRow = { ...newRow, isNew: false };
        setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
        setHasChanges(true);
        return updatedRow;
    };

    // methods for when user clicks on cancel button per row
    const handleCancelClick = (id) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });

        const editedRow = rows.find((row) => row.id === id);
        if (editedRow.isNew) {
            setRows(rows.filter((row) => row.id !== id));
        }
    };

    const handleRowEditStop = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    // method for selecting rows for deletion
    const handleSelectionChange = (newSelectionModel) => {
        setDeleteIds(newSelectionModel);
    };

    const handleSaveAllChanges = async () => {
        const response = await request(
            "post",
            endpoint,
            rows,
            "application/json",
            [
                {
                    statusCode: 200,
                    type: "success",
                    message: t("mapping_edit_success"),
                },
                {
                    statusCode: 400,
                    type: "error",
                    message: t("mapping_edit_failure"),
                },
            ]
        );

        if (response.status === 200) {
            setHasChanges(false);
            fetchData();
        }
    };

    const handelDiscardChanges = () => {
        setHasChanges(false);
        fetchData();
    };

    const handleDelete = async () => {
        const response = await request(
            "post",
            endpoint_delete,
            { Id: deleteIds },
            "application/json",
            [
                {
                    statusCode: 200,
                    type: "success",
                    message: t("mapping_delete_success"),
                },
                {
                    statusCode: 400,
                    type: "error",
                    message: t("mapping_delete_failure"),
                },
            ]
        );

        if (response.status === 200) {
            setOpenDialog(false);
            fetchData();
        }
    };

    const columns = [
        {
            field: "XAVVTable",
            headerName: "XAVVTable",
            type: "string",
            flex: 1,
            align: "left",
            headerAlign: "left",
            editable: true,
        },
        {
            field: "XAVVField",
            headerName: "XAVVField",
            type: "string",
            flex: 1,
            editable: true,
        },
        {
            field: "XAVVValue",
            headerName: "XAVVValue",
            flex: 1,
            editable: true,
        },
        { field: "SAPValue", headerName: "SAPValue", flex: 1, editable: true },
        {
            field: "actions",
            type: "actions",
            headerName: t("edit"),
            flex: 0.5, // Adjust width to fill available space
            cellClassName: "actions",
            getActions: ({ id }) => {
                const isInEditMode =
                    rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label="Save"
                            sx={{ color: "primary.main" }}
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    return (
        <>
            {/* main container */}
            <Stack
                direction="column"
                spacing={1}
                alignSelf="center"
                sx={{ width: { md: "100%", lg: "90%", xl: "70%" } }}
            >
                {/* heading */}
                <Typography variant="h1" className="pageHeadline">
                    {t("modules_title")}
                </Typography>

                {loading && <Skeleton variant="rounded" height={400} />}

                {!loading && (
                    <Box
                        sx={{
                            height: 500,
                            width: "100%",
                            "& .MuiInputBase-input": {
                                color: "#000 !important",
                            },
                            marginBottom: "50px !important",
                        }}
                    >
                        <Stack sx={{ mb: 2 }} direction="row" gap={2}>
                            <ButtonGroup variant="contained">
                                <Button
                                    startIcon={<SaveIcon />}
                                    onClick={handleSaveAllChanges}
                                    disabled={!hasChanges}
                                >
                                    {t("save_changes")}
                                </Button>
                                <Button
                                    startIcon={<CloseIcon />}
                                    onClick={handelDiscardChanges}
                                    disabled={!hasChanges}
                                >
                                    {t("discard_changes")}
                                </Button>
                            </ButtonGroup>

                            <Button
                                variant="contained"
                                startIcon={<DeleteIcon />}
                                onClick={() => {
                                    setOpenDialog(true);
                                }}
                                disabled={deleteIds.length === 0 || hasChanges}
                            >
                                {t("delete_mappings")}
                            </Button>
                        </Stack>
                        <DataGrid
                            rows={rows}
                            columns={columns}
                            editMode="row"
                            checkboxSelection
                            onRowSelectionModelChange={handleSelectionChange}
                            rowModesModel={rowModesModel}
                            onRowModesModelChange={handleRowModesModelChange}
                            onRowEditStop={handleRowEditStop}
                            processRowUpdate={processRowUpdate}
                            slots={{ toolbar: EditToolbar }}
                            slotProps={{
                                toolbar: { rows, setRows, setRowModesModel },
                            }}
                        />
                    </Box>
                )}
            </Stack>
            <ConfirmDialog
                isOpen={openDialog}
                headline={t("delete_mappings")}
                question={t("mapping_delete_question", {
                    number: deleteIds.length,
                })}
                handleClose={() => {
                    setOpenDialog(false);
                }}
                handleConfirm={handleDelete}
            />
        </>
    );
}
