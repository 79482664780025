import { Dialog, DialogContent, Stack, Typography } from "@mui/material";

import { useState } from "react";

import { useTranslation } from "react-i18next";

import mailPlaceholder from "../assets/card_placeholders/mail_placeholder.svg";
import sharepointPlaceholder from "../assets/card_placeholders/sharepoint_placeholder.svg";

import { formatDateTime } from "../utils";

import ConfirmDialog from "./ConfirmDialog";
import Image from "./Image";
import JSONViewer from "./JsonViewer";
import TooltipButton from "./TooltipButton";

/**
 * @param {object} props
 * @param {string} props.compKey - key for each iteration
 * @param {object} props.data - data of module
 * @param {object} props.config - config of module
 * @param {boolean} props.isOpen - boolean value whether dialog is open
 * @param {boolean} props.isEditable - boolean value whether config is editable
 * @param {Function} props.handleClose - function to close detail view
 * @param {Function} props.handleEdit - function to enable editing mode
 * @param {Function} props.handleCancel - function to disable editing mode
 * @param {Function} props.handleDelete - function to delete module config
 * @param {Function} props.handleSave - function to save new configuration values
 * @returns dialog window with details of module configuration
 */

const ModuleDetailView = ({
    compKey,
    data,
    config,
    isOpen,
    isEditable,
    handleClose,
    handleEdit,
    handleDelete,
    handleSave,
    handleCancel,
}) => {
    // i18n
    const { t } = useTranslation();

    const [configCopy, setConfigCopy] = useState(config);

    // value whether delete dialogue window for certain module is open
    const [openDelete, setOpenDelete] = useState(null);

    const handleOpenDelete = (item) => {
        setOpenDelete(item);
    };

    const handleCloseDelete = () => {
        setOpenDelete(null);
    };

    if (data && configCopy) {
        return (
            <>
                <Dialog className="module" open={isOpen} key={compKey}>
                    <DialogContent className="module">
                        <Stack direction="column">
                            {/* dialog header */}
                            <Stack
                                direction="row"
                                alignItems="start"
                                justifyContent="space-between"
                                spacing={2}
                                sx={{ marginBottom: "30px" }}
                            >
                                <Stack
                                    direction="row"
                                    spacing={2}
                                    alignItems="center"
                                >
                                    <Image
                                        src={
                                            data.Module.toLowerCase().includes(
                                                "outlook"
                                            )
                                                ? mailPlaceholder
                                                : sharepointPlaceholder
                                        }
                                        alt={data.Module}
                                        className="detailView"
                                        initHeight={50}
                                        style={{
                                            borderRadius: "35%",
                                            boxShadow:
                                                "8px 8px 15px -8px rgba(0,0,0,0.3)",
                                        }}
                                    />
                                    <Typography
                                        variant="h3"
                                        className="dialogHeadline"
                                    >
                                        {data.Module}
                                    </Typography>
                                </Stack>

                                {/* close dialog button */}
                                <TooltipButton
                                    tooltip={`${t("close")}`}
                                    handleClick={handleClose}
                                    icon="close"
                                    iconStyle={{ color: "white !important" }}
                                    buttonClass="cardIcon"
                                    iconClass="cardIcon"
                                    ariaLabel="close current detail dialog"
                                />
                            </Stack>

                            {/* module form */}
                            <Stack px="10px">
                                <JSONViewer
                                    config={config}
                                    updateConfig={setConfigCopy}
                                    isEditable={isEditable}
                                />

                                {/* timestamps */}
                                {Object.entries(data).map(([key, value]) =>
                                    key === "CreateTimestamp" ||
                                    key === "ChangeTimestamp" ? (
                                        <Stack direction="row" key={key}>
                                            <Typography
                                                variant="body1"
                                                className="detailView key"
                                            >
                                                {`${t(`module_${key}`)}:`}&nbsp;
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                className="detailView value"
                                            >
                                                {`${formatDateTime(value)}`}
                                            </Typography>
                                        </Stack>
                                    ) : null
                                )}
                            </Stack>

                            {/* button stack */}
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                sx={{ marginTop: "20px" }}
                            >
                                {/* show buttons when edit mode is deactivated  */}
                                {!isEditable && (
                                    <>
                                        {/* edit button */}
                                        <TooltipButton
                                            tooltip={`${t("edit")}`}
                                            handleClick={handleEdit}
                                            icon="edit"
                                            buttonClass="cardIcon"
                                            iconClass="cardIcon"
                                            ariaLabel="edit current configuration"
                                        />

                                        {/* delete button */}
                                        <TooltipButton
                                            tooltip={`${t("delete")}`}
                                            handleClick={() => {
                                                handleOpenDelete(data.Module);
                                            }}
                                            icon="delete"
                                            buttonClass="cardIcon"
                                            iconClass="cardIcon"
                                            ariaLabel="delete current module configuration"
                                        />
                                    </>
                                )}

                                {/* show buttons when edit mode is activated */}
                                {isEditable && (
                                    <>
                                        {/* save button */}
                                        <TooltipButton
                                            tooltip={`${t("save")}`}
                                            handleClick={() => {
                                                handleSave(
                                                    data.Module,
                                                    configCopy
                                                );
                                            }}
                                            icon="save"
                                            buttonClass="cardIcon"
                                            iconClass="cardIcon"
                                            disabled={config !== configCopy}
                                            iconStyle={{
                                                color:
                                                    config !== configCopy
                                                        ? "grey"
                                                        : "",
                                                transition: "0.3s",
                                            }}
                                        />

                                        {/* cancel button */}
                                        <TooltipButton
                                            tooltip={`${t("cancel")}`}
                                            handleClick={handleCancel}
                                            icon="cancel"
                                            buttonClass="cardIcon"
                                            iconClass="cardIcon"
                                            ariaLabel="cancel editing configuration"
                                        />
                                    </>
                                )}
                            </Stack>
                        </Stack>
                    </DialogContent>
                </Dialog>

                {/* delete dialogue */}
                <ConfirmDialog
                    isOpen={openDelete === data.Module}
                    headline={t("module_delete")}
                    question={t("module_delete_question", {
                        module_name: data.Module,
                    })}
                    handleClose={() => handleCloseDelete()}
                    handleConfirm={() => {
                        handleDelete(data.Module, true);
                        handleCloseDelete();
                    }}
                />
            </>
        );
    }
};

export default ModuleDetailView;
