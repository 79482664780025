import AddIcon from "@mui/icons-material/Add";
import CachedIcon from "@mui/icons-material/Cached";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import SaveIcon from "@mui/icons-material/Save";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import { IconButton } from "@mui/material";

import PropTypes from "prop-types";

import CustomTooltip from "./CustomTooltip";

/**
 * @param {object} props
 * @param {string} props.tooltip - text to be shown in Tooltip window
 * @param {string} props.icon - string with type of icon, e. g. 'edit' or 'delete'
 * @param {Function} props.handleClick - function to call when button is clicked
 * @param {string} props.buttonClass - class name for button
 * @param {object} props.buttonStyle - custom style attributes for button
 * @param {string} props.iconClass - class name for icon
 * @param {object} props.iconStyle - custom style attributes for icon
 * @param {boolean} props.disabled - boolean value to dis- or enable button
 * @param {string} props.ariaLabel - text to use for accessibility
 * @returns {JSX.Element} custom button with tooltip and pre-defined icons
 */

const TooltipButton = ({
    tooltip,
    icon,
    handleClick,
    buttonClass,
    buttonStyle,
    iconClass,
    iconStyle,
    disabled,
    ariaLabel,
}) => {
    return (
        <CustomTooltip title={tooltip}>
            <IconButton
                aria-label={ariaLabel}
                className={buttonClass}
                onClick={handleClick}
                sx={buttonStyle}
                disabled={disabled}
            >
                {icon === "add" && (
                    <AddIcon sx={iconStyle} className={iconClass} />
                )}
                {icon === "edit" && (
                    <EditIcon sx={iconStyle} className={iconClass} />
                )}
                {icon === "delete" && (
                    <DeleteIcon sx={iconStyle} className={iconClass} />
                )}
                {icon === "save" && (
                    <SaveIcon sx={iconStyle} className={iconClass} />
                )}
                {icon === "cancel" && (
                    <CancelIcon sx={iconStyle} className={iconClass} />
                )}
                {icon === "close" && (
                    <CloseIcon sx={iconStyle} className={iconClass} />
                )}
                {icon === "open" && (
                    <OpenInNewIcon sx={iconStyle} className={iconClass} />
                )}
                {icon === "visibilityOff" && (
                    <VisibilityOffIcon sx={iconStyle} className={iconClass} />
                )}
                {icon === "visibility" && (
                    <VisibilityIcon sx={iconStyle} className={iconClass} />
                )}
                {icon === "copy" && (
                    <ContentCopyIcon sx={iconStyle} className={iconClass} />
                )}
                {icon === "reload" && (
                    <CachedIcon sx={iconStyle} className={iconClass} />
                )}
                {icon === "check" && (
                    <CheckIcon sx={iconStyle} className={iconClass} />
                )}
            </IconButton>
        </CustomTooltip>
    );
};

TooltipButton.propTypes = {
    tooltip: PropTypes.string,
    icon: PropTypes.string,
    handleClick: PropTypes.func,
    buttonClass: PropTypes.string,
    buttonStyle: PropTypes.object,
    iconClass: PropTypes.string,
    iconStyle: PropTypes.object,
    disabled: PropTypes.bool,
    ariaLabel: PropTypes.string,
};

export default TooltipButton;
