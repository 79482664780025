import { useMediaQuery } from "@mui/material";

import theme from "../assets/muiCustomTheme.js";

/**
 * @param {object} props
 * @param {string} props.src - string to image path
 * @param {string} props.alt - string with alternative text for screenreader
 * @param {string} props.className - class name
 * @param {number} props.initHeight - initial height for default media screen size
 * @param {object} props.style - custom style attributes
 * @param {string} props.ariaLabel - text to use for accessibility
 * @returns {JSX.Element} custom element to display images
 */

const Image = ({ src, alt, className, initHeight, style, ariaLabel }) => {
    const isMd = useMediaQuery(theme.breakpoints.up("md"));
    const isLg = useMediaQuery(theme.breakpoints.up("lg"));
    const isXl = useMediaQuery(theme.breakpoints.up("xl"));
    const isXxl = useMediaQuery(theme.breakpoints.up("xxl"));

    let height;

    if (isXxl) {
        height = `${initHeight * 1.2}px`;
    } else if (isXl) {
        height = `${initHeight}px`;
    } else if (isLg) {
        height = `${initHeight * 1.4}px`;
    } else if (isMd) {
        height = `${initHeight * 0.95}px`;
    } else {
        height = `${initHeight * 0.8}px`;
    }

    const responsiveStyle =
        className === "navbarLogo"
            ? isXxl
                ? { paddingTop: "10px", paddingBottom: "10px" }
                : isXl
                ? {}
                : isLg
                ? { paddingTop: "10px", paddingBottom: "10px" }
                : null
            : className === "detailView"
            ? isXxl
                ? {}
                : null
            : {};

    const combinedStyle = { ...style, ...responsiveStyle };

    return (
        <img
            src={src}
            alt={alt}
            height={height}
            style={combinedStyle}
            aria-label={ariaLabel}
        />
    );
};

export default Image;
