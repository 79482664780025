import axios from "axios";

import { createContext, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useSnackbar } from "notistack";

const AuthContext = createContext();

// eslint-disable-next-line react/prop-types
export const AuthProvider = ({ children }) => {
    // i18n
    const { t } = useTranslation();
    // notification
    const { enqueueSnackbar } = useSnackbar();
    const endpoint_signout = `${process.env.REACT_APP_BACKEND_URL}/signout`;

    const navigate = useNavigate();

    /**
     *
     * @param {string} method - 'get', 'post', 'put', 'patch' or 'delete'
     * @param {string} endpoint - string with endpoint URI
     * @param {object} body - request body - should be null if method is 'get' or 'delete'
     * @param {string} contentType - data type of request body
     * @param {object[]} setters - setter functions for 'get' method
     * @param  {Notification[]} notifications - array of expected response status codes
     * @returns {any} response or only response status code based on success of function
     */
    const request = async (
        method,
        endpoint,
        body,
        contentType,
        notifications = []
    ) => {
        try {
            let response = null;

            // fetching data from backend
            if (method.toLowerCase() === "get") {
                response = await axios.get(
                    endpoint,
                    {
                        withCredentials: true,
                    },
                    requestHeader(method.toUpperCase(), null)
                );
            }
            // creating new data in backend OR for batch deletion
            else if (method.toLowerCase() === "post") {
                response = await axios.post(
                    endpoint,
                    body,
                    { withCredentials: true },
                    requestHeader(method.toUpperCase(), null, contentType)
                );
            }
            // updating existing data in backend
            else if (method.toLowerCase() === "put") {
                response = await axios.put(
                    endpoint,
                    body,
                    { withCredentials: true },
                    requestHeader(method.toUpperCase(), null, contentType)
                );
            }
            // updating specific part of data in backend
            else if (method.toLowerCase() === "patch") {
                response = await axios.patch(
                    endpoint,
                    body,
                    { withCredentials: true },
                    requestHeader(method.toUpperCase(), null, contentType)
                );
            }
            // delete one particular data from backend
            else if (method.toLowerCase() === "delete") {
                response = await axios.delete(
                    endpoint,
                    { withCredentials: true },
                    requestHeader(method.toUpperCase(), null)
                );
            }

            if (response.status === 200 || response.status === 201) {
                const successNotification = notifications.find(
                    (notification) =>
                        notification.statusCode === 200 ||
                        notification.statusCode === 201
                );
                if (successNotification) {
                    enqueueSnackbar(successNotification.message, {
                        variant: successNotification.type,
                        preventDuplicate: true,
                    });
                }
            }

            return response;
        } catch (reason) {
            // if response is raised by server
            if (reason.response) {
                const statusCode = reason.response.status;

                const matchingNotification = notifications.find(
                    (notification) => notification.statusCode === statusCode
                );

                const isExpected = notifications.some(
                    (notification) => notification.statusCode === statusCode
                );

                // access token expired
                if (statusCode === 401 && !isExpected) {
                    logout(true);

                    // after redirect
                } else if (statusCode === 307 && !isExpected) {
                    return null;

                    // not enough permissions
                } else if (statusCode === 403 && !isExpected) {
                    navigate("/");
                    enqueueSnackbar(t("forbidden"), {
                        variant: "error",
                        preventDuplicate: true,
                    });

                    // wrong request method or url
                } else if (statusCode === 404) {
                    enqueueSnackbar(t("invalid_method"), {
                        variant: "error",
                        preventDuplicate: true,
                    });

                    // field validation for forms
                } else if (statusCode === 422) {
                    const invalidField = reason.response.data?.detail?.errors
                        ? reason.response.data.detail.errors[0]?.loc?.[0]
                        : reason.response.data.detail;

                    let message;

                    // does invalid field match field we want to point out when validating?
                    if (
                        invalidField.includes(matchingNotification.message?.[0])
                    ) {
                        message = matchingNotification.message?.[1];
                    } else {
                        message = matchingNotification.message?.[2];
                    }
                    enqueueSnackbar(message, {
                        variant: matchingNotification.type,
                        preventDuplicate: true,
                    });

                    // server error
                } else if (statusCode === 500) {
                    const errorDetail = reason.response.data?.detail;

                    // connection to database failed
                    if (errorDetail === "Internal Server Error") {
                        enqueueSnackbar(t("server_error"), {
                            variant: "error",
                            preventDuplicate: true,
                        });

                        // query execution failed
                    } else {
                        enqueueSnackbar(t("unknown_error"), {
                            variant: "error",
                            preventDuplicate: true,
                        });
                    }

                    // handle expected error
                } else if (matchingNotification) {
                    enqueueSnackbar(matchingNotification.message, {
                        variant: matchingNotification.type,
                        preventDuplicate: true,
                    });

                    // unexpected error
                } else {
                    enqueueSnackbar(t("unknown_error"), {
                        variant: "error",
                        preventDuplicate: true,
                    });
                }

                return reason.response.status;
            }

            // no server response
            else {
                enqueueSnackbar(t("server_error"), {
                    variant: "error",
                    preventDuplicate: true,
                });
            }
        }
    };

    const login = async (endpoint, formData) => {
        if (formData.get("email") === "" || formData.get("password") === "") {
            enqueueSnackbar(t("signin_empty"), {
                variant: "warning",
                preventDuplicate: true,
            });
        } else {
            const response = await request(
                "post",
                endpoint,
                formData,
                "multipart/form-data",
                [
                    {
                        statusCode: 401,
                        type: "error",
                        message: t("signin_failure"),
                    },
                    {
                        statusCode: 403,
                        type: "error",
                        message: t("disabled_failure"),
                    },
                    {
                        statusCode: 404,
                        type: "error",
                        message: t("signin_failure"),
                    },
                ]
            );

            if (response?.status === 200) {
                navigate("/");
                window.location.reload();
            }
        }
    };

    const logout = async (forcedLogout) => {
        const response = await request("get", endpoint_signout, null, null);
        if (response?.status === 200) {
            navigate("/signIn");
        }
        if (forcedLogout) {
            enqueueSnackbar(t("expired_token"), {
                variant: "error",
                preventDuplicate: true,
            });
        }
    };

    const value = {
        login,
        logout,
        request,
    };

    return (
        <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    );
};

/**
 * @typedef {Object} Notification
 * @property {number} statusCode - The HTTP status code (e. g. 200, 401, 404)
 * @property {string} type - 'success', 'error', 'warning' or 'info'
 * @property {...string} message - Message to display when this status code is received
 */

/**
 * Send an HTTP request
 *
 * @returns {{
 *      request: (
 *          method: string,
 *          endpoint: string,
 *          body: object | null,
 *          contentType: string,
 *          notifications: Notification[]
 *      ) => Promise<any>
 * }}
 */
export const useAuth = () => {
    return useContext(AuthContext);
};

//utils
const requestHeader = (method, body, contentType = "application/json") => {
    const headers = {
        method: method,
        headers: {
            "Content-Type": contentType,
        },
    };
    if (body !== null && method !== "DELETE") {
        headers.body = JSON.stringify(body);
    }
    return headers;
};
