import { Container } from "@mui/material";

import JsonView from "react18-json-view";

import "react18-json-view/src/style.css";

/**
 * @param {object} props
 * @param {object} props.config - json data
 * @param {Function} props.updateConfig - function to handle json edit
 * @param {boolean} props.isEditable - boolean value whether edit mode is active
 * @returns {JSX.Element} JSON Viewer to edit json data
 */
const JSONViewer = ({ config, updateConfig, isEditable }) => {
    return (
        <Container className={"jsonViewer " + (isEditable ? "" : "off")}>
            <JsonView
                src={config}
                editable={isEditable}
                collapseStringsAfterLength={60}
                enableClipboard={false}
                onChange={({ src }) => {
                    updateConfig(src);
                }}
                className={isEditable ? "" : "off"}
                style={{
                    color: isEditable ? "#545454" : "#434343",
                    "--json-property": isEditable ? "#aa5d00" : "#b36e1b",
                    "--json-index": isEditable ? "#007299" : "#218aad",
                    "--json-number": isEditable ? "#007299" : "#218aad",
                    "--json-string": isEditable ? "#008000" : "#1f941f",
                    "--json-boolean": isEditable ? "#d91e18" : "#db3c37",
                    "--json-null": isEditable ? "#d91e18" : "#db3c37",
                }}
            />
        </Container>
    );
};

export default JSONViewer;
