import {
    ErrorOutlineOutlined,
    InfoOutlined,
    KeyboardArrowUp,
    TaskAltOutlined,
    WarningAmberOutlined,
} from "@mui/icons-material";

import {
    Box,
    Container,
    Fab,
    Fade,
    ThemeProvider,
    useScrollTrigger,
} from "@mui/material";

import { SnackbarProvider } from "notistack";

import PropTypes from "prop-types";

import { Helmet } from "react-helmet";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import "./App.css";
import { AuthProvider } from "./AuthProvider.js";

import "./assets/i18n";
import theme from "./assets/muiCustomTheme.js";
import routes from "./assets/routes.js";

import NavBar from "./components/NavBar.js";
import ProtectedRoute from "./components/ProtectedRoute.js";

function App(props) {
    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <SnackbarProvider
                    maxSnack={3}
                    autoHideDuration={5000}
                    iconVariant={{
                        success: <TaskAltOutlined />,
                        error: <ErrorOutlineOutlined />,
                        warning: <WarningAmberOutlined />,
                        info: <InfoOutlined />,
                    }}
                >
                    <AuthProvider>
                        <NavBar></NavBar>

                        <Container className="main">
                            <Routes>
                                {routes.map((route, index) =>
                                    route.name !== "Sign In" &&
                                    route.name !== "Token" ? (
                                        <Route
                                            key={index}
                                            path={route.path}
                                            element={
                                                <>
                                                    <Helmet>
                                                        <title>{`eInvoicing - ${route.name}`}</title>
                                                    </Helmet>
                                                    <ProtectedRoute>
                                                        {route.element}
                                                    </ProtectedRoute>
                                                </>
                                            }
                                        />
                                    ) : null
                                )}

                                {/* sign in route */}
                                <Route
                                    path={routes[3].path}
                                    element={routes[3].element}
                                />
                            </Routes>
                        </Container>

                        {/* scroll to top button */}
                        <ScrollTop {...props}>
                            <Fab size="small" aria-label="scroll back to top">
                                <KeyboardArrowUp />
                            </Fab>
                        </ScrollTop>
                    </AuthProvider>
                </SnackbarProvider>
            </BrowserRouter>
        </ThemeProvider>
    );
}

export default App;

// custom button to reset site view
function ScrollTop(props) {
    const { children } = props;

    // detect if user has scrolled past threshold
    const trigger = useScrollTrigger({
        // disable delay in trigger
        disableHysteresis: true,
        // amount of scroll (in pixels) before triggering
        threshold: 100,
    });

    const handleClick = (event) => {
        const anchor = (event.target.ownerDocument || document).querySelector(
            // find the element with id 'back-to-top-anchor' (in NavBar.js)
            "#back-to-top-anchor"
        );

        // if anchor element is found, scroll to it
        if (anchor) {
            anchor.scrollIntoView({
                block: "center",
            });
        }
    };

    return (
        // fade in the button when trigger condition is met (user scrolls past threshold)
        <Fade in={trigger}>
            <Box
                onClick={handleClick}
                role="presentation"
                sx={{ position: "fixed", bottom: 16, right: 16 }}
            >
                {children} {/* render passed children (fab / button) */}
            </Box>
        </Fade>
    );
}

// PropTypes for type-checking and documentation
ScrollTop.propTypes = {
    // ensure children is a single React element
    children: PropTypes.element.isRequired,
    // optional prop to override default window
    window: PropTypes.func,
};
