import { TextField } from "@mui/material";

import React from "react";
import { useTranslation } from "react-i18next";

/**
 * @param {object} props
 * @param {object} props.column - ...
 * @returns {JSX.Element} search filter for homepage
 */

const DefaultColumnFilter = ({
    column: { filterValue, preFilteredRows, setFilter },
}) => {
    // i18n
    const { t } = useTranslation();

    return (
        <TextField
            value={filterValue || ""}
            onChange={(e) => {
                setFilter(e.target.value || undefined);
            }}
            placeholder={t("search")}
            variant="outlined"
            size="small"
            className="textfilter"
        />
    );
};

export default DefaultColumnFilter;
