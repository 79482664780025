import {
    Button,
    Grid,
    Stack,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";

import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { useAuth } from "../AuthProvider";

import TooltipButton from "../components/TooltipButton";
import UserDialog from "../components/UserDialog";

const UserManager = () => {
    // i18n
    const { t } = useTranslation();
    // authorization
    const { request } = useAuth();

    // endpoints in use
    const endpoint = `${process.env.REACT_APP_BACKEND_URL}/user`;
    const endpoint_api = `${process.env.REACT_APP_BACKEND_URL}/api`;

    // all users from database
    const [loadingUsers, setLoadingUsers] = useState(false);
    const [users, setUsers] = useState([]);

    // value for copy button
    const [copyIcon, setCopyIcon] = useState("copy");

    // string value to change modification mode
    const [mode, setMode] = useState(null);
    // value whether create user dialogue window is open
    const [isDialogOpen, toggleDialogOpen] = useState(false);

    // active user so he cannot disable himself
    const [activeUser, setActiveUser] = useState();

    const fetchData = async (init = false) => {
        if (init) {
            setLoadingUsers(true);
        }

        const response = await request("GET", endpoint, null, null, [
            {
                statusCode: 400,
                type: "error",
                message: t("load_failure", { prop: t("user", { count: 2 }) }),
            },
        ]);

        if (response?.status === 200 || response?.status === 204) {
            setActiveUser(response.headers.user);
            setUsers(response.data);
            setLoadingUsers(false);
        }
    };

    useEffect(() => {
        fetchData(true);
    }, []);

    const handleCreate = async (formData) => {
        const body = {
            user_email: formData.email,
            user_password: formData.password,
            user_fname: formData.firstName,
            user_lname: formData.lastName,
            user_scope: formData.userRole,
            user_authid: "",
        };

        const response = await request(
            "post",
            endpoint,
            body,
            "application/json",
            [
                {
                    statusCode: 201,
                    type: "success",
                    message: t("create_success", {
                        prop: t("user", { count: 1 }),
                    }),
                },
                {
                    statusCode: 400,
                    type: "error",
                    message: t("create_failure", {
                        prop: t("user", { count: 1 }),
                    }),
                },
                {
                    statusCode: 409,
                    type: "warning",
                    message: t("user_creation_conflict"),
                },
                {
                    statusCode: 422,
                    type: "warning",
                    message: [
                        "email",
                        t("user_creation_invalid_mail"),
                        t("user_creation_invalid_value"),
                    ],
                },
            ]
        );

        if (response?.status === 201) {
            handleCloseDialog();
            fetchData();
        }
    };

    const handleEdit = async (formData) => {
        const body = {
            user_email: formData.email,
            user_password: formData.password,
            user_fname: formData.firstName,
            user_lname: formData.lastName,
            user_scope: formData.userRole,
        };

        const response = await request(
            "put",
            endpoint + "/edit",
            body,
            "application/json",
            [
                {
                    statusCode: 200,
                    type: "success",
                    message: t("edit_success", {
                        prop: t("user", { count: 1 }),
                    }),
                },
                {
                    statusCode: 400,
                    type: "error",
                    message: t("edit_failure", {
                        prop: t("user", { count: 1 }),
                    }),
                },
                {
                    statusCode: 422,
                    type: "warning",
                    message: [
                        "email",
                        t("user_creation_invalid_mail"),
                        t("user_creation_invalid_value"),
                    ],
                },
            ]
        );

        if (response?.status === 200) {
            handleCloseDialog();
            fetchData();
        }
    };

    const handleToggleDisable = async (userEmail, currentDisabledState) => {
        const action = currentDisabledState === "True" ? "enable" : "disable";
        const body = {
            Email: userEmail,
            action: action,
        };

        const response = await request(
            "put",
            endpoint + "/disable",
            body,
            "application/json",
            [
                {
                    statusCode: 200,
                    type: "success",
                    message: t(`user_${action}_success`, {
                        user: userEmail,
                    }),
                },
                {
                    statusCode: 400,
                    type: "error",
                    message: t(`user_${action}_failure`, {
                        user: userEmail,
                    }),
                },
            ]
        );

        if (response?.status === 200) {
            fetchData();
        }
    };

    const handleApiKey = async (userId) => {
        const body = {
            user_id: userId,
        };

        const response = await request(
            "put",
            endpoint_api,
            body,
            "application/json",
            [
                {
                    statusCode: 200,
                    type: "success",
                    message: t("create_success", {
                        prop: "API Key",
                    }),
                },
                {
                    statusCode: 400,
                    type: "error",
                    message: t("create_failure", {
                        prop: "API Key",
                    }),
                },
            ]
        );

        if (response?.status === 200) {
            fetchData();
        }
    };

    const copyToClipboard = (textToCopy, index) => {
        navigator.clipboard
            .writeText(textToCopy)
            .then(() => {
                setCopyIcon(index);

                setTimeout(() => {
                    setCopyIcon("copy");
                }, 2000);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleOpenDialog = (mode) => {
        setMode(mode);
        toggleDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setMode(null);
        toggleDialogOpen(false);
    };

    return (
        <>
            {/* main container */}
            <Stack
                direction="column"
                spacing={1}
                alignSelf="center"
                sx={{ width: { md: "100%", lg: "90%", xl: "70%" } }}
            >
                {/* heading */}
                <Typography variant="h1" className="pageHeadline">
                    {t("user_management_title")}
                </Typography>

                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleOpenDialog("create")}
                    sx={{
                        width: "max-content",
                        alignSelf: "end",
                    }}
                >
                    {t("create_user")}
                </Button>
                {/* data container */}
                <Stack direction="row">
                    {/* list container */}
                    <Grid container width="100%">
                        <Table
                            className="invoiceItems"
                            style={{
                                width: "100%",
                                tableLayout: "fixed",
                            }}
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell className="items itemHead name">
                                        {t("first_name")}
                                    </TableCell>
                                    <TableCell className="items itemHead name">
                                        {t("last_name")}
                                    </TableCell>
                                    <TableCell className="items itemHead name">
                                        {t("Email")}
                                    </TableCell>
                                    <TableCell className="items itemHead role">
                                        {t("role")}
                                    </TableCell>
                                    <TableCell className="items itemHead api">
                                        {t("api_key")}
                                    </TableCell>
                                    <TableCell className="items itemHead action">
                                        {t("disabled")}
                                    </TableCell>
                                    <TableCell className="items itemHead action"></TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {loadingUsers && (
                                    <TableRow>
                                        <TableCell colSpan={7}>...</TableCell>
                                    </TableRow>
                                )}

                                {!loadingUsers &&
                                    users &&
                                    users.map((user, index) => (
                                        <TableRow
                                            key={index}
                                            className="itemRow"
                                        >
                                            <TableCell className="items">
                                                {user.FirstName}
                                            </TableCell>
                                            <TableCell className="items">
                                                {user.LastName}
                                            </TableCell>
                                            <TableCell className="items">
                                                {user.Email}
                                            </TableCell>
                                            <TableCell className="items">
                                                {user.Scope}
                                            </TableCell>
                                            <TableCell className="items">
                                                {user.ApiKey !== "None" ? (
                                                    <Stack
                                                        direction="row"
                                                        spacing={1}
                                                    >
                                                        <TooltipButton
                                                            key={copyIcon}
                                                            tooltip={t("copy")}
                                                            icon={
                                                                copyIcon ===
                                                                index
                                                                    ? "check"
                                                                    : "copy"
                                                            }
                                                            ariaLabel="copy to clipboard"
                                                            handleClick={() =>
                                                                copyToClipboard(
                                                                    user.ApiKey,
                                                                    index
                                                                )
                                                            }
                                                            iconStyle={{
                                                                color:
                                                                    copyIcon ===
                                                                    index
                                                                        ? "green"
                                                                        : "",
                                                            }}
                                                        />
                                                        <TooltipButton
                                                            tooltip={t(
                                                                "regenerate"
                                                            )}
                                                            icon="reload"
                                                            ariaLabel="regenerate API key"
                                                            handleClick={() =>
                                                                handleApiKey(
                                                                    user.UserId
                                                                )
                                                            }
                                                        />
                                                    </Stack>
                                                ) : (
                                                    <Button
                                                        variant="contained"
                                                        onClick={() =>
                                                            handleApiKey(
                                                                user.UserId
                                                            )
                                                        }
                                                    >
                                                        {t("generate_api_key")}
                                                    </Button>
                                                )}
                                            </TableCell>
                                            <TableCell className="items">
                                                <Switch
                                                    variant="contained"
                                                    checked={
                                                        user.Disabled === "True"
                                                    }
                                                    onClick={() =>
                                                        handleToggleDisable(
                                                            user.Email,
                                                            user.Disabled
                                                        )
                                                    }
                                                    disabled={
                                                        user.Email ===
                                                        activeUser
                                                    }
                                                />
                                            </TableCell>

                                            <TableCell className="items">
                                                <TooltipButton
                                                    tooltip={t("edit")}
                                                    icon="edit"
                                                    handleClick={() =>
                                                        handleOpenDialog(user)
                                                    }
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>

                        <UserDialog
                            mode={mode}
                            open={isDialogOpen}
                            handleClose={() => handleCloseDialog()}
                            handleCreate={handleCreate}
                            handleEdit={handleEdit}
                        />
                    </Grid>
                </Stack>
            </Stack>
        </>
    );
};

export default UserManager;
