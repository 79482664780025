import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

import { Box, Button, Grid, Skeleton, Stack, Typography } from "@mui/material";

import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFilters, usePagination, useSortBy, useTable } from "react-table";

import { useAuth } from "../AuthProvider";

import DateRangeColumnFilter from "../components/DatePickFilter";
import StatusFilter from "../components/DropdownFilter";
import HomepageGrid from "../components/HomepageGrid";
import DefaultColumnFilter from "../components/TextFilter";

export default function Homepage() {
    // i18n
    const { t } = useTranslation();
    // authorization
    const { request } = useAuth();

    // endpoints in use
    const endpoint = `${process.env.REACT_APP_BACKEND_URL}/invoices`;

    // all invoices from database
    const [loadingData, setLoadingData] = useState(false);
    const [data, setData] = useState([]);

    const fetchData = async () => {
        setLoadingData(true);
        const response = await request("get", endpoint, null, null, []);

        if (response?.status === 200 || response?.status === 204) {
            setData(response.data);
            setLoadingData(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const columns = useMemo(
        () => [
            {
                Header: t("InvoiceNumber"),
                accessor: "FieldValue",
                Filter: DefaultColumnFilter,
                medium: 1.5,
                large: 1.7,
            },
            {
                Header: t("Recipient"),
                accessor: "Recipient",
                Filter: DefaultColumnFilter,
                medium: 2.3,
                large: 2.4,
            },
            {
                Header: t("Sender"),
                accessor: "Sender",
                Filter: DefaultColumnFilter,
                medium: 2.3,
                large: 2.4,
            },
            {
                Header: t("CreateTimestamp"),
                accessor: "CreateTimestamp",
                Filter: DateRangeColumnFilter,
                filter: "dateRangeFilter",
                medium: 2.4,
                large: 2.2,
                Cell: ({ value }) => new Date(value).toLocaleString(),
            },
            {
                Header: t("DocumentStatus"),
                accessor: "DocumentStatus",
                Filter: StatusFilter,
                medium: 1.1,
                large: 1.1,
            },
        ],
        [t]
    );

    const filterTypes = useMemo(
        () => ({
            dateRangeFilter: (rows, id, filterValue) => {
                const [startDate, endDate] = filterValue;

                if (!startDate && !endDate) {
                    return rows;
                }

                return rows.filter((row) => {
                    const rowDate = new Date(row.values[id]);

                    if (startDate && endDate) {
                        return rowDate >= startDate && rowDate <= endDate;
                    } else if (startDate) {
                        return rowDate >= startDate;
                    } else if (endDate) {
                        return rowDate <= endDate;
                    }

                    return true;
                });
            },
        }),
        []
    );

    const memorizedData = useMemo(
        () => (Array.isArray(data) && data.length > 0 ? data : []),
        [data]
    );

    const tableInstance = useTable(
        {
            columns,
            data: memorizedData,
            filterTypes,
            initialState: { pageIndex: 0, pageSize: 30 },
        },
        useFilters,
        useSortBy,
        usePagination
    );

    const {
        headerGroups,
        page,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        nextPage,
        previousPage,
        gotoPage,
        pageCount,
        state: { pageIndex, pageSize },
    } = tableInstance;

    return (
        <>
            {/* main container */}
            <Stack
                direction="column"
                spacing={1}
                alignSelf="center"
                sx={{ width: { md: "100%", lg: "90%", xl: "70%" } }}
            >
                {/* heading */}
                <Typography variant="h1" className="pageHeadline">
                    {t("homepage_title")}
                </Typography>

                {/* data container */}
                <Stack direction="row">
                    {/* list container */}
                    <Grid container width="100%">
                        {loadingData && (
                            <>
                                <Skeleton
                                    variant="rounded"
                                    sx={{
                                        marginBottom: "15px",
                                        borderRadius: "40px",
                                        width: "100%",
                                        height: {
                                            md: "70px",
                                            lg: "77px",
                                            xl: "71px",
                                            xxl: "74px",
                                        },
                                    }}
                                />
                                <Skeleton
                                    variant="rounded"
                                    sx={{
                                        marginBottom: "15px",
                                        borderRadius: "40px",
                                        width: "100%",
                                        height: {
                                            md: "70px",
                                            lg: "77px",
                                            xl: "71px",
                                            xxl: "74px",
                                        },
                                    }}
                                />
                                <Skeleton
                                    variant="rounded"
                                    sx={{
                                        marginBottom: "15px",
                                        borderRadius: "40px",
                                        width: "100%",
                                        height: {
                                            md: "70px",
                                            lg: "77px",
                                            xl: "71px",
                                            xxl: "74px",
                                        },
                                    }}
                                />
                                <Skeleton
                                    variant="rounded"
                                    sx={{
                                        marginBottom: "15px",
                                        borderRadius: "40px",
                                        width: "100%",
                                        height: {
                                            md: "70px",
                                            lg: "77px",
                                            xl: "71px",
                                            xxl: "74px",
                                        },
                                    }}
                                />
                            </>
                        )}

                        {!loadingData && data.length > 0 && (
                            <>
                                {/* list header */}
                                <Grid item width="100%">
                                    <Grid container className="homeHead">
                                        {headerGroups.map((headerGroup) => (
                                            <Grid
                                                container
                                                {...headerGroup.getHeaderGroupProps()}
                                                key={headerGroup.id}
                                            >
                                                {headerGroup.headers.map(
                                                    (column) => (
                                                        <Grid
                                                            item
                                                            md={column.medium}
                                                            lg={column.large}
                                                            className="homeHead"
                                                            {...column.getHeaderProps(
                                                                column.getSortByToggleProps()
                                                            )}
                                                            style={{
                                                                cursor: "pointer",
                                                                display: "flex",
                                                                flexDirection:
                                                                    "row",
                                                            }}
                                                            key={column.id}
                                                        >
                                                            <Stack
                                                                direction="column"
                                                                justifyContent="space-between"
                                                                gap={1}
                                                            >
                                                                <Stack
                                                                    direction="row"
                                                                    gap={1}
                                                                >
                                                                    <Typography
                                                                        variant="h2"
                                                                        className="homeHead"
                                                                    >
                                                                        {column.render(
                                                                            "Header"
                                                                        )}
                                                                    </Typography>
                                                                    {column.isSorted ? (
                                                                        column.isSortedDesc ? (
                                                                            <ArrowDownwardIcon fontSize="medium" />
                                                                        ) : (
                                                                            <ArrowUpwardIcon fontSize="medium" />
                                                                        )
                                                                    ) : (
                                                                        <ArrowUpwardIcon
                                                                            fontSize="medium"
                                                                            sx={{
                                                                                opacity:
                                                                                    "0.25",
                                                                                "&:hover":
                                                                                    {
                                                                                        opacity:
                                                                                            "1",
                                                                                        transition:
                                                                                            "0.3s",
                                                                                    },
                                                                            }}
                                                                        />
                                                                    )}
                                                                </Stack>
                                                                {/* filter input */}
                                                                <Box
                                                                    onMouseDown={(
                                                                        e
                                                                    ) =>
                                                                        e.stopPropagation()
                                                                    }
                                                                    onClick={(
                                                                        e
                                                                    ) =>
                                                                        e.stopPropagation()
                                                                    }
                                                                    sx={{
                                                                        display:
                                                                            {
                                                                                xs: "none",
                                                                                md: "block",
                                                                            },
                                                                    }}
                                                                >
                                                                    {column.canFilter
                                                                        ? column.render(
                                                                              "Filter"
                                                                          )
                                                                        : null}
                                                                </Box>
                                                            </Stack>
                                                        </Grid>
                                                    )
                                                )}
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Grid>

                                {!loadingData &&
                                    (data && data.length > 0 ? (
                                        // list entries
                                        page.map((row, index) => {
                                            prepareRow(row);
                                            return (
                                                <HomepageGrid
                                                    data={row.original}
                                                    key={index}
                                                />
                                            );
                                        })
                                    ) : (
                                        <Stack
                                            alignItems="center"
                                            width="100%"
                                            mt={2}
                                        >
                                            <Typography
                                                variant="body1"
                                                className="home"
                                            >
                                                {t("no_documents")}
                                            </Typography>
                                        </Stack>
                                    ))}

                                {page.length > 0 ? (
                                    <Stack
                                        direction="row"
                                        spacing={2}
                                        justifyContent="center"
                                        alignItems="center"
                                        sx={{
                                            marginTop: "20px",
                                            width: "100%",
                                        }}
                                    >
                                        {/* First Page Button */}
                                        <Button
                                            className="pagination"
                                            disabled={!canPreviousPage}
                                            onClick={() => gotoPage(0)}
                                        >
                                            {"<<"}
                                        </Button>

                                        {/* Previous Page Button */}
                                        <Button
                                            className="pagination"
                                            disabled={!canPreviousPage}
                                            onClick={() => previousPage()}
                                        >
                                            {"<"}
                                        </Button>

                                        {/* Current Page */}
                                        <Typography
                                            variant="body1"
                                            sx={{ px: "10px" }}
                                        >{`${pageIndex + 1} / ${
                                            pageOptions.length
                                        }`}</Typography>

                                        {/* Next Page Button */}
                                        <Button
                                            className="pagination"
                                            disabled={!canNextPage}
                                            onClick={() => nextPage()}
                                        >
                                            {">"}
                                        </Button>

                                        {/* Last Page Button */}
                                        <Button
                                            className="pagination"
                                            disabled={!canNextPage}
                                            onClick={() =>
                                                gotoPage(pageCount - 1)
                                            }
                                        >
                                            {">>"}
                                        </Button>
                                    </Stack>
                                ) : (
                                    <Stack
                                        alignItems="center"
                                        width="100%"
                                        mt={2}
                                    >
                                        <Typography
                                            variant="body1"
                                            className="home"
                                        >
                                            {t("no_documents")}
                                        </Typography>
                                    </Stack>
                                )}
                            </>
                        )}

                        {!loadingData && data.length === 0 && (
                            <Stack alignItems="center" width="100%" mt={2}>
                                <Typography variant="body1" className="home">
                                    {t("no_documents")}
                                </Typography>
                            </Stack>
                        )}
                    </Grid>
                </Stack>
            </Stack>
        </>
    );
}
