import { Grid, Typography } from "@mui/material";

import { Link } from "react-router-dom";

import { formatDateTime } from "../utils";

import Chip from "../components/HomepageChip";

/**
 * @param {object} props
 * @param {object} props.data - invoice data to be shown as row on homepage
 * @returns {JSX.Element} customized row for homepage
 */
const HomepageGrid = ({ data }) => {
    return (
        <Link
            key={data["DocumentId"]}
            to={`/eInvoice/${data["DocumentId"]}`}
            className="homepage"
        >
            <Grid item width="100%">
                <Grid container className="home">
                    {/* invoice number */}
                    <Grid item className="home" md={1.5} lg={1.7}>
                        <Typography variant="body1" className="home">
                            {data["FieldValue"]}
                        </Typography>
                    </Grid>

                    {/* recipient */}
                    <Grid item className="home" md={2.3} lg={2.4}>
                        <Typography variant="body1" className="home">
                            {data["Recipient"]}
                        </Typography>
                    </Grid>

                    {/* sender */}
                    <Grid item className="home" md={2.3} lg={2.4}>
                        <Typography variant="body1" className="home">
                            {data["Sender"]}
                        </Typography>
                    </Grid>

                    {/* receipt date */}
                    <Grid item className="home" md={2.4} lg={2.2}>
                        <Typography variant="body1" className="home">
                            {formatDateTime(data["CreateTimestamp"])}
                        </Typography>
                    </Grid>

                    {/* document status */}
                    <Grid
                        item
                        className="home"
                        md={1.1}
                        lg={1.1}
                        sx={{
                            padding: "0px !important",
                            justifyContent: "start !important",
                        }}
                    >
                        <Chip
                            statusCode={
                                data["Retrieval"] === "3" &&
                                data["DocumentStatus"] === "102"
                                    ? "blocked"
                                    : data["DocumentStatus"]
                            }
                        />
                    </Grid>

                    {/* completion date */}
                    <Grid item className="home" md={2.4} lg={2.2}>
                        <Typography variant="body1" className="home">
                            {formatDateTime(data["FinishTimestamp"])}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Link>
    );
};

export default HomepageGrid;
