import { Tooltip } from "@mui/material";

/**
 * @param {Object} props
 * @param {Element} props.children - child component(s) to use inside custom Tooltip
 * @param {string} props.title - text to be shown in Tooltip window
 * @returns {JSX.Element} customized tooltip to fit webservice theme
 */

const CustomTooltip = ({ children, title }) => {
    return (
        <Tooltip
            title={title}
            PopperProps={{
                modifiers: [
                    {
                        name: "offset",
                        options: {
                            offset: [0, -10],
                        },
                    },
                ],
            }}
        >
            {children}
        </Tooltip>
    );
};

export default CustomTooltip;
