import i18n from "./assets/i18n";

/**
 * @param {string} dateTimeString - string to format to date or date time
 * @returns {string} reformatted string if input was valid - else 'INVALID DATE'
 */
export function formatDateTime(dateTimeString) {
    if (dateTimeString === "" || dateTimeString === "None") {
        return "";
    }

    if (isDateTime(dateTimeString) === "date") {
        const year = parseInt(dateTimeString.substring(0, 4), 10);
        const month = parseInt(dateTimeString.substring(4, 6), 10) - 1; // Months are 0-based
        const day = parseInt(dateTimeString.substring(6, 8), 10);

        const dt = new Date(year, month, day);
        return dt.toLocaleDateString(i18n.language, {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        });
    } else if (isDateTime(dateTimeString) === "date_time") {
        const dt = new Date(dateTimeString);
        return dt.toLocaleDateString(i18n.language, {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hour12: i18n.language === "de" ? false : true,
        });
    } else {
        return "INVALID DATE";
    }

    /**
     * @param {string} str - string to check if date or date time format
     * @returns {string} 'date_time', 'date' or 'invalid' based on parameter
     */
    function isDateTime(str) {
        // split string into date and time part
        const [datePart, timePart] = str.split(" ");
        datePart.toUpperCase();

        // look for time part in string
        if (timePart && timePart.trim() !== "") {
            return "date_time";
        }

        // TODO: Do we need a more precise test here?
        const regex = /^\d{8}$/;
        if (regex.test(str)) {
            return "date";
        }

        // return value if no time part is found
        return "invalid";
    }
}

/**
 * @param {string} currencyString - string to format to right currency format based on current language
 * @returns {string} reformatted currency string
 */
export function formatCurrency(currencyString) {
    if (currencyString !== "" && currencyString !== undefined) {
        let cr = currencyString;
        if (i18n.language === "de") {
            if (currencyString.includes(".")) {
                cr = currencyString.replace(".", ",");
            } else {
                cr = currencyString + ",00";
            }

            let parts = cr.split(",");
            if (parts.length === 2) {
                parts[1] = parts[1].slice(0, 2).padEnd(2, "0");
                cr = parts.join(",");
            }
        } else {
            if (!currencyString.includes(".")) {
                cr = currencyString + ".00";
            }

            let parts = cr.split(".");
            if (parts.length === 2) {
                parts[1] = parts[1].slice(0, 2).padEnd(2, "0");
                cr = parts.join(".");
            }
        }
        return cr;
    } else {
        return currencyString;
    }
}
