import Config from "../pages/configuration";
import InvoiceOverview from "../pages/eInvoice_";
import Homepage from "../pages/homepage";
import Mapping from "../pages/sapconfiguration.js";
import SignIn from "../pages/signIn.js";
import UserManager from "../pages/userManager.js";

const handleTabClick = () => {
    window.location.reload();
};

const routes = [
    {
        path: "/",
        element: <Homepage />,
        name: "Homepage",
        onClick: handleTabClick,
    },
    {
        path: "/config/",
        element: <Config />,
        name: "Configuration",
        onClick: handleTabClick,
    },
    {
        path: "/eInvoice/:documentId",
        element: <InvoiceOverview />,
        name: "eInvoiceDetail",
        onClick: handleTabClick,
    },
    {
        path: "/signin",
        element: <SignIn />,
        name: "Sign In",
        onClick: handleTabClick,
    },
    {
        path: "/signin/azure_error",
        element: <SignIn />,
        name: "Sign In with Azure Error",
        onClick: handleTabClick,
    },
    {
        path: "/sapconfig",
        element: <Mapping />,
        name: "SAP Configuration",
        onClick: handleTabClick,
    },
    {
        path: "/user",
        element: <UserManager />,
        name: "Manage User",
        onClick: handleTabClick,
    },
];

export default routes;
