import { Stack, TextField } from "@mui/material";

import React from "react";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { useTranslation } from "react-i18next";

/**
 * @param {object} props
 * @param {*} props.column - ...
 * @returns {JSX.Element} date picker to filter invoices based on receipt date
 */

const DateRangeColumnFilter = ({ column: { filterValue = [], setFilter } }) => {
    // i18n
    const { t } = useTranslation();

    const [startDate, endDate] = filterValue;

    return (
        <>
            <Stack direction="row" gap={2}>
                <DatePicker
                    selected={startDate}
                    onChange={(date) => {
                        setFilter((old = []) => [date, old[1]]);
                    }}
                    placeholderText={t("start_date")}
                    isClearable
                    maxDate={new Date()}
                    customInput={
                        <TextField
                            variant="outlined"
                            size="small"
                            className="datepicker"
                            placeholder={t("start_date")}
                        />
                    }
                />
                <DatePicker
                    selected={endDate}
                    onChange={(date) => {
                        setFilter((old = []) => [old[0], date]);
                    }}
                    placeholderText={t("end_date")}
                    isClearable
                    maxDate={new Date()}
                    customInput={
                        <TextField
                            variant="outlined"
                            size="small"
                            className="datepicker"
                            placeholder={t("end_date")}
                        />
                    }
                />
            </Stack>
        </>
    );
};

export default DateRangeColumnFilter;
