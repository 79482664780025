import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import {
    AppBar,
    Box,
    Button,
    IconButton,
    ListItemIcon,
    Menu,
    MenuItem,
    Toolbar,
    Typography,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

import eInvoicingLogo from "../assets/eInvoicing_logo.svg";
import routes from "../assets/routes";

import { useAuth } from "../AuthProvider";

import Image from "./Image";
import LanguageSwitch from "./LanguageSwitch";

/**
 * @returns {JSX.Element} navigation bar for top of each page
 */

const NavBar = () => {
    //i18n
    const { t } = useTranslation();

    const { logout } = useAuth();

    //setting options in dropdown menu for profile button
    const settings = ["Logout"];

    const navigate = useNavigate();
    const currentPath = useLocation().pathname;

    //hook for profile dropdown menu
    const [userMenuVisible, setUserMenuVisibility] = useState(null);
    //hook for page dropdown menu
    const [navMenuVisible, setNavMenuVisibility] = useState(null);
    //array of routes to show inside navbar
    const [navbarRoutes, setNavbarRoutes] = useState([]);

    useEffect(() => {
        setNavbarRoutes(
            routes.filter(
                (route) =>
                    route.name !== "eInvoiceDetail" &&
                    !route.name.startsWith("Sign In")
            )
        );
    }, [routes]);

    //open profile dropdown menu
    const handleOpenUserMenu = (event) => {
        setUserMenuVisibility(event.currentTarget);
    };
    //close profile dropdown menu
    const handleCloseUserMenu = () => {
        setUserMenuVisibility(null);
    };

    //open page dropdown menu
    const handleOpenNavMenu = (event) => {
        setNavMenuVisibility(event.currentTarget);
    };
    //close page dropdown menu
    const handleCloseNavMenu = (route) => {
        if (route !== null) {
            navigate(route);
        }
        setNavMenuVisibility(null);
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar id="navbar">
                <Toolbar>
                    {/* logo */}
                    <Image
                        src={eInvoicingLogo}
                        alt="avvaneo eInvoicing"
                        ariaLabel="circle with mountain-like shapes inside next to webservice name"
                        initHeight={50}
                        className="navbarLogo"
                    />

                    {!currentPath.startsWith("/signIn") && (
                        <>
                            {/* page redirect buttons inside navbar */}
                            <Box
                                sx={{
                                    flexGrow: 1,
                                    display: { xs: "none", md: "flex" },
                                }}
                            >
                                {navbarRoutes.map((route) => (
                                    <Link key={route.path} to={route.path}>
                                        <Button
                                            aria-label={`redirect to ${route.name.toLowerCase()}`}
                                        >
                                            <Typography
                                                variant="body1"
                                                className="navbar"
                                            >
                                                {t(`${route.name}`)}
                                            </Typography>
                                        </Button>
                                    </Link>
                                ))}
                            </Box>

                            <Box
                                sx={{
                                    flexGrow: 1,
                                    display: { xs: "flex", md: "none" },
                                }}
                            >
                                {/* button to open page dropdown menu */}
                                <IconButton
                                    size="large"
                                    color="inherit"
                                    onClick={handleOpenNavMenu}
                                    aria-label="open page dropdown menu"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                >
                                    <MenuIcon />
                                </IconButton>

                                {/* page dropdown menu */}
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={navMenuVisible}
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "left",
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "left",
                                    }}
                                    open={Boolean(navMenuVisible)}
                                    onClick={() => {
                                        handleCloseNavMenu(null);
                                    }}
                                    sx={{
                                        display: { xs: "block", md: "none" },
                                    }}
                                >
                                    {navbarRoutes.map((route) => (
                                        <MenuItem
                                            key={route.name}
                                            onClick={() => {
                                                handleCloseNavMenu(route.path);
                                            }}
                                            aria-label={`redirect to ${route}`}
                                        >
                                            <Typography
                                                variant="body1"
                                                className="navbar"
                                            >
                                                {t(`${route.name}`)}
                                            </Typography>
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </Box>
                        </>
                    )}

                    {/* right side of navbar */}
                    <Box sx={{ flexGrow: 0 }}>
                        {/* button group to switch languages */}
                        <LanguageSwitch />

                        {!currentPath.startsWith("/signin") && (
                            <>
                                {/* button to open profile dropdown menu */}
                                <IconButton
                                    onClick={handleOpenUserMenu}
                                    sx={{ p: 0, ml: "1rem" }}
                                    aria-label="open profile dropdown menu"
                                >
                                    <AccountCircleIcon className="loginIcon" />
                                </IconButton>

                                {/* profile dropdown menu */}
                                <Menu
                                    sx={{ mt: "45px" }}
                                    id="menu-appbar"
                                    anchorEl={userMenuVisible}
                                    anchorOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                    }}
                                    open={Boolean(userMenuVisible)}
                                    onClose={handleCloseUserMenu}
                                >
                                    {settings.map((setting) => (
                                        <MenuItem
                                            key={setting}
                                            onClick={handleCloseUserMenu}
                                            aria-label={
                                                setting === "Logout"
                                                    ? "log out"
                                                    : `redirect to ${setting}`
                                            }
                                        >
                                            {setting === "Logout" && (
                                                <Link
                                                    component="button"
                                                    onClick={() => {
                                                        logout();
                                                    }}
                                                    sx={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                    }}
                                                >
                                                    <ListItemIcon>
                                                        <LogoutIcon />
                                                    </ListItemIcon>
                                                    <Typography
                                                        variant="body1"
                                                        className="navbar"
                                                    >
                                                        {setting}
                                                    </Typography>
                                                </Link>
                                            )}
                                            {setting !== "Logout" && (
                                                <Link
                                                    to=""
                                                    sx={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                    }}
                                                >
                                                    <Typography
                                                        variant="body1"
                                                        className="navbar"
                                                    >
                                                        {setting}
                                                    </Typography>
                                                </Link>
                                            )}
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </>
                        )}
                    </Box>
                </Toolbar>
            </AppBar>

            {/* point to reset view when pressing scroll-to-top button */}
            <Toolbar id="back-to-top-anchor" display="hidden" />
        </Box>
    );
};

export default NavBar;
