import { Button, ButtonGroup, Typography } from "@mui/material";

import i18n from "i18next";

import React from "react";

/**
 * @returns {JSX.Element} button group to switch language of webservice
 */

const LanguageSwitch = () => {
    // available languages
    const languages = ["en", "de"];

    /**
     * @param {string} language - value from array `languages[]`
     * @returns {void} sets `i18n.language` to chosen language
     */
    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
        document.getElementById("language-change-announcement").innerText =
            "language changed to " + (language === "de" ? "german" : "english");
    };

    return (
        <ButtonGroup>
            {languages.map((lng) => (
                <React.Fragment key={lng}>
                    {/* aria-announcement, only relevant for screenreader */}
                    <div
                        key={`${lng}-aria`}
                        id="language-change-announcement"
                        style={{ display: "none" }}
                        aria-live="polite"
                    ></div>
                    {/* button to change language */}
                    <Button
                        key={lng}
                        className="language"
                        sx={{
                            textDecoration:
                                lng === i18n.language ? "underline" : "none",
                            ":hover": {
                                textDecoration:
                                    lng === i18n.language
                                        ? "underline"
                                        : "none",
                            },
                        }}
                        onClick={() => {
                            changeLanguage(lng);
                        }}
                        aria-label={
                            "change language to " +
                            (lng === "de" ? "german" : "english")
                        }
                    >
                        <Typography variant="body1" className="navbar">
                            {lng.toUpperCase()}
                        </Typography>
                    </Button>
                </React.Fragment>
            ))}
        </ButtonGroup>
    );
};

export default LanguageSwitch;
